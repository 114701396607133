<template>
  <div class="container">
    <div class="section-header pb-5">
      <h2>Получить прайс</h2>
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon">
          <i class="fas fa-gifts fa-2x"></i>
        </div>
        <div class="divider-custom-line"></div>
      </div>
    </div>
    <div class="section-content">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <p class="blockquote text-dark pb-2">Оставьте заявку через форму ниже и мы вышлем вам полный прайс наших подарков.</p>
          <div v-if="showMessage" class="alert alert-success mb-4" role="alert">
            <h4 class="alert-heading">Спасибо за вашу заявку!</h4>
            <div class="pb-1">Наш менеджер  скоро свяжется с вами.</div>
          </div>
          <div v-if="showErrorMessage" class="alert alert-danger mb-4" role="alert">
            <h4 class="alert-heading">Что-то пошло не так</h4>
            <div class="pb-1">Попробуйте позже...</div>
          </div>
          <form v-show="!showMessage" autocomplete="off">
            <fieldset :disabled="formDisabled">
              <div class="form-group">
                <label for="inputName">Ваше имя</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="inputName"
                  name="name"
                  v-model="inputName"
                  v-validate="'required'"
                  placeholder="Ввести имя"
                >
                <div class="invalid-feedback">
                  Пожалуйста введите имя
                </div>
              </div>
              <div class="form-group">
                <label for="inputCity">Город</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="inputCity"
                  name="city"
                  v-model="inputCity"
                  v-validate="'required'"
                  placeholder="Ввести город"
                >
                <div class="invalid-feedback">
                  Пожалуйста введите город
                </div>
              </div>
              <div class="form-group">
                <label for="inputPhone">Номер телефона</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="inputPhone"
                  name="phone"
                  v-model="inputPhone"
                  v-validate="'required|length:15'"
                  placeholder="Ввести номер телефона"
                  v-mask="'+7 ### ### ####'"
                >
                <div class="invalid-feedback">
                  Пожалуйста введите номер телефона
                </div>
              </div>
              <div class="form-group">
                <label for="inputEmail">Email</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="inputEmail"
                  name="email"
                  v-model="inputEmail"
                  v-validate="'required|email'"
                  placeholder="Ввести email"
                >
                <div class="invalid-feedback">
                  Пожалуйста введите email
                </div>
              </div>
              <div class="form-check form-group">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="inputAgree"
                  name="agree"
                  v-model="inputAgree"
                  v-validate="'required'"
                >
                <label class="form-check-label" for="inputAgree">Даю согласие на обработку персональных данных</label>
                <div class="invalid-feedback">
                  Вы должны дать согласие перед отправкой заявки
                </div>
              </div>
              <button
                type="button"
                class="btn btn-custom-primary"
                v-on:click.prevent="handleClick()"
              >Получить прайс</button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationForm',
  data: () => {
    return {
      inputName: '',
      inputCity: '',
      inputPhone: '',
      inputEmail: '',
      inputAgree: false,
      formDisabled: false,
      showMessage: false,
      showErrorMessage: false
    }
  },
  methods: {
    handleClick () {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.formDisabled = true
          this.showErrorMessage = false

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: this.inputName, city: this.inputCity, phone: this.inputPhone, email: this.inputEmail })
          }

          fetch('index.php', requestOptions)
            .then(async response => {
              const data = await response.json()
              console.log(data)

              this.inputName = ''
              this.inputCity = ''
              this.inputPhone = ''
              this.inputEmail = ''
              this.inputAgree = false

              this.$validator.reset()

              this.showMessage = true
            })
            .catch(error => {
              console.error('There was an error!', error)
              this.showErrorMessage = true
            })

          this.formDisabled = false
        }
      })
    }
  }
}
</script>

<style>
</style>

import Vue from 'vue'
import App from './App.vue'

import VeeValidate from 'vee-validate'
import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false

Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid"
  }
})

Vue.directive('mask', VueMaskDirective)

new Vue({
  render: h => h(App),
}).$mount('#app')

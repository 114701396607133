<template>
  <div>
    <ApplicationForm />
  </div>
</template>

<script>
import ApplicationForm from './components/ApplicationForm.vue'

export default {
  name: 'App',
  components: {
    ApplicationForm
  }
}
</script>

<style>
</style>
